@media (min-width: 767px) {
  .navbar-nav .dropdown-menu .caret {
    transform: rotate(-90deg);
  }
}
a {
  color: #0167d0;
}
a:hover {
  color: #014e9d;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #b09367;
}
h1 {
  margin-top: 0px;
  margin-bottom: 1em;
  font-family: "Times New Roman";
}
h3.author {
  margin-top: 0px;
}
h3 a {
  color: #b09367;
}
h3 a:hover {
  color: #96794e;
  text-decoration: none;
}
/*#background{
    background-image:url(@default_bkg);
    background-attachment:fixed;
    background-repeat:no-repeat;
    background-size:cover;
    content:"";
    opacity: 0.1;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    position: fixed;
    z-index:-1;
}*/
/*#maincont{
    background-image: url(@default_bkg);
    background-attachment: fixed;
    background-position:0 -40px;
    background-size:cover;
    background-repeat:no-repeat;
}*/
.navbar {
  margin-bottom: 0px;
  border-radius: 0px;
}
#portalNav-row .navbar-inverse {
  background-color: #b09367;
  border-color: #b09367;
}
#portalNav-row .navbar-inverse a {
  color: white;
}
#portalNav-row .navbar-inverse ul.navbar-nav a {
  padding: 15px 10px;
}
#portalNav-row .navbar-inverse ul.navbar-nav li.open > a {
  background-color: #91754b;
}
#portalNav-row .navbar-inverse ul[class*=ul_level] {
  background-color: white;
}
#portalNav-row .navbar-inverse ul[class*=ul_level] a {
  color: #91754b;
  padding: 3px 20px !important;
}
#portalNav-row .navbar-inverse .navbar-collapse {
  border-color: #91754b;
}
#portalNav-row .navbar-inverse .navbar-toggle {
  border-color: #91754b;
}
#portalNav-row .navbar-inverse .navbar-toggle:hover {
  background-color: #91754b;
}
#portalNav-row span.search {
  float: right;
  width: 120px;
  padding: 8px 0;
  margin-right: -15px;
}
#portalNav-row span.search .ya-site-form__submit {
  display: none;
}
#portalNav-row span.search .ya-site-form__input-text {
  border-radius: 2px;
}
#portalNav-row span.search .ya-site-form__input-text::-webkit-input-placeholder {
  color: #91754b;
}
#portalNav-row span.search .ya-site-form__input-text:-moz-placeholder {
  /* FF 4-18 */
  color: #91754b;
}
#portalNav-row span.search .ya-site-form__input-text::-moz-placeholder {
  /* FF 19+ */
  color: #91754b;
}
#portalNav-row span.search .ya-site-form__input-text:-ms-input-placeholder {
  /* IE 10+ */
  color: #91754b;
}
#siteNav-row {
  font-size: 1.2em;
}
#siteNav-row .navbar-inverse {
  background-color: #b09367;
  border-color: #b09367;
}
#siteNav-row .navbar-inverse a {
  color: white;
}
#siteNav-row .navbar-inverse ul.navbar-nav li.open > a {
  background-color: #c1ab89;
}
#siteNav-row .navbar-inverse ul[class*=ul_level] {
  background-color: white;
}
#siteNav-row .navbar-inverse ul[class*=ul_level] a {
  color: #91754b;
}
#siteNav-row .navbar-inverse .navbar-collapse {
  border-color: #c1ab89;
  border-top: none;
}
#siteNav-row .navbar-inverse .navbar-toggle {
  border-color: #c1ab89;
}
#siteNav-row .navbar-inverse .navbar-toggle:hover {
  background-color: #c1ab89;
}
#site-title-row #title-cont {
  background-image: url("/f/fon_econ.png");
  background-position: left center;
  position: relative;
}
#site-title-row #logo {
  background-image: url(/f/logo.png);
  width: 110px;
  height: 110px;
  position: absolute;
  float: left;
  margin-left: 20px;
  z-index: 10;
  background-size: 80%;
  background-position: center center;
  background-repeat: no-repeat;
}
#site-title-row h1 {
  position: relative;
  z-index: 20;
  display: inline-block;
  text-transform: uppercase;
  font-family: Times New Roman;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.65);
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}
#site-title-row h1 a {
  color: white;
}
#site-title-row h1 a:hover {
  text-decoration: none;
}
#site-title-row p {
  color: white;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.65);
  margin-top: -8px;
  font-size: 1.4em;
}
#site-title-row p a {
  color: white;
}
#site-title-row p a:hover {
  text-decoration: none;
  color: white;
}
.content {
  opacity: 0.92;
  background-color: white;
}
.content .waypath .current-page {
  color: #b09367;
}
.navbar-brand {
  padding: 2px 15px;
}
footer {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(176, 147, 103, 0.8);
  color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
footer a {
  color: white;
}
footer a:hover {
  color: white;
}
footer .address,
footer .author {
  text-align: right;
}
.after-footer {
  position: relative;
  margin-top: -5px;
  height: 101vh;
  background-image: url("/f/fon_econ.png");
  background-attachment: fixed;
  background-position: 0 -40px;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.sidenav,
.text-left {
  padding-bottom: 2em;
}
.numbers span:first-child {
  margin-left: 0.7em;
}
.number-list p.year {
  margin-bottom: 0px;
  margin-top: 10px;
  background-color: #f6f2ed;
  color: white;
  padding-left: 0.7em;
}
.number-list .number {
  display: inline-block;
  padding: 0px 5px 0px 0px;
}
